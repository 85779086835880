<template>
  <div class="page-container budget-page">
    <portal to="page-name">Orçamento</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Orçamento
          <span v-if="form.code"> - </span>
          <small class="text-secondary"> {{ form.code }}</small>
        </div>
      </div>
      <div class="card-body">
        <div v-if="authorized">
          <div class="resume">
            <h6 class="h6 text-primary">{{ form.patient.name }}</h6>
            <div class="columns">
              <div class="column col-4 col-sm-12 form-group">
                <label class="form-label text-bold">Data</label>
                {{ form.date | date('DD/MM/YYYY') }}
              </div>
              <div class="column col-4 col-sm-12 form-group">
                <label class="form-label text-bold">Status</label>
                {{ status.getName(form.status) }}
              </div>
              <div class="column col-4 col-sm-12 form-group">
                <label class="form-label text-bold">Responsável</label>
                {{ form.collaborator ? form.collaborator.name : '-' }}
              </div>
              <div class="column col-12 form-group" v-if="form.notes">
                <label class="form-label text-bold">Observações</label>
                {{ form.notes }}
              </div>
            </div>
            <div class="divider" />
            <div class="stenci-title">Procedimentos</div>
            <table class="table table-hover">
              <thead>
              <tr>
                <th width="50px">#</th>
                <th>Procedimento</th>
                <th>Profissional</th>
                <th class="text-center" width="20px">Quantidade</th>
                <th class="text-right">Valor</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(expense, i) in form.expenses" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ expense.code | tuss }} - {{ expense.name }}</td>
                <td>{{ expense.professional ? expense.professional.name : '-' }}</td>
                <td class="text-center">x{{ expense.quantity }}</td>
                <td class="text-right">{{ expense.final | currency }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <h6>Dados principais</h6>
          <div class="columns form-group">
            <div class="column col-2 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.date.$error}">
              <label class="form-label">Validade</label>
              <dx-input-date
                id="date" name="validity" class="form-input"
                v-model="form.date" @blur="$v.form.date.$touch()"
                @focus="setValidityDate" />
              <template v-if="$v.form.date.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.date.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-if="!$v.form.date.date">Data inválida</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.date.minDate">Data inválida</div>
              </template>
            </div>
            <div class="column col-3 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.status.$error}">
              <label for="status" class="form-label">Status</label>
              <select id="status" class="form-select"
                      v-model="form.status"
                      @blur="$v.form.status.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="(text, value) in status.statuses"
                        :value="value" :key="value">{{ text }}</option>
              </select>
              <template v-if="$v.form.status.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.status.required">Campo obrigatório</div>
              </template>
            </div>
          </div>
          <div class="columns">
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.insurance.plan.id.$error}">
              <label for="insurance" class="form-label">Convênio parceiro</label>
              <select id="insurance" name="insurance" :disabled="form.expenses.length > 0"
                      class="form-select" v-model="form.insurance.plan.id"
                      @blur="$v.form.insurance.plan.id.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="(item, i) in insurances"
                        :value="item.plan.id" :key="i">{{ item.customName }}</option>
              </select>
              <template v-if="$v.form.insurance.plan.id.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.insurance.plan.id.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label for="notes" class="form-label">Observações</label>
              <input type="text" id="notes" name="notes" class="form-input"
                     v-model="form.notes">
            </div>
          </div>
          <div class="divider" />
          <h6 class="h6">Dados do paciente</h6>
          <div class="columns">
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.name.$error}">
              <label class="form-label">Nome do paciente</label>
              <dx-autocomplete
                id="patient-name"
                v-model="patient"
                :source="findPatient"
                label="name"
                track-by="id"
                :readonly="!!form.patient.id"
                @select="setPatient"
                @blur="onPatientBlur"
                :debounce="800"
                placeholder="Nome do paciente, data de nascimento ou CPF">
                <template slot="action">
                  <button
                    @click="unsetPatient"
                    class="btn btn-action input-group-btn btn-icon"
                    :class="patient ? 'btn-gray' : 'btn-neutral'"
                    tabindex="-1">
                    <fa-icon :icon="['fal', patient ? 'times' : 'search']"></fa-icon>
                  </button>
                </template>
                <template v-slot="{ item }">
                  <div>{{ item.name }}</div>
                  <small class="text-primary" v-if="item.id === null">
                    (paciente sem cadastro)
                  </small>
                  <small class="text-primary" v-else>
                    <fa-icon :icon="['fal', 'gift']"></fa-icon>
                    {{ item.birthDate | date }} ({{ item.birthDate | dateOld }})
                  </small>
                </template>
              </dx-autocomplete>
              <template v-if="$v.form.patient.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.name.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.birthDate.$error}">
              <label class="form-label">Nascimento</label>
              <dx-input-date
                id="appointment-patient-birth-date"
                class="form-input"
                v-model="form.patient.birthDate"
                :readonly="!!form.patient.id"
                @blur="$v.form.patient.birthDate.$touch()"
              />
              <template v-if="$v.form.patient.birthDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.birthDate.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.birthDate.date">Data inválida</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.patient.birthDate.maxDate">
                  Data não pode ser superior a hoje
                </div>
              </template>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.gender.$error}">
              <label class="form-label">Sexo</label>
              <select id="gender" name="gender"
                      v-model="form.patient.gender"
                      :disabled="!!form.patient.id"
                      class="form-select" @change="$v.form.patient.gender.$touch()">
                <option value="">[Selecione]</option>
                <option value="female">Feminino</option>
                <option value="male">Masculino</option>
              </select>
              <template v-if="$v.form.patient.gender.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.gender.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-3 col-md-4 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.phone.$error}">
              <label for="phone" class="form-label">Telefone</label>
              <input type="text" id="phone" name="phone" v-model="form.patient.phone"
                     class="form-input" maxlength="14" @blur="$v.form.patient.phone.$touch()"
                     placeholder="(00) 0000-0000" autocomplete="nope" v-mask-phone.br>
              <template v-if="$v.form.patient.phone.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.phone.phone">Telefone inválido</div>
              </template>
            </div>
            <div class="column col-3 col-md-4 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.cellphone.$error}">
              <label for="cellphone" class="form-label">Celular</label>
              <input type="text" id="cellphone" name="cellphone"
                     @blur="$v.form.patient.cellphone.$touch()"
                     v-model="form.patient.cellphone" class="form-input" autocomplete="nope"
                     placeholder="(00) 0 0000-0000" v-mask-phone.br>
              <template v-if="$v.form.patient.cellphone.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.cellphone.phone">Telefone inválido</div>
              </template>
            </div>
            <div class="column col-6 col-md-4 col-sm-12 form-group"
                 :class="{'has-error': $v.form.patient.email.$error}">
              <label for="email" class="form-label">Email</label>
              <input type="text" id="email" name="email"
                     v-model="form.patient.email"
                     class="form-input" @blur="$v.form.patient.email.$touch()"
                     placeholder="nome@email.com" autocomplete="nope">
              <template v-if="$v.form.patient.email.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.patient.email.email">Email inválido</div>
              </template>
            </div>
          </div>
          <div class="divider" />
          <h6 class="h6">Despesas</h6>
          <div class="columns form-group">
            <div class="column col-12">
              <label class="form-label">Nome</label>
              <dx-autocomplete
                id="expense-name"
                :custom-label="setName"
                track-by="id"
                v-model="expense"
                :source="loadExpense"
                :disabled="!form.insurance.plan.id"
                @select="onSelectExpense"
                :debounce="800"
                placeholder="Código ou nome do procedimento / exame">
                <template slot="action">
                  <button class="btn btn-neutral btn-action btn-icon input-group-btn"
                          :class="{loading: expenseModal.loading}"
                          :disabled="expenseModal.loading"
                          @click="openExpenseModal">
                    <fa-icon :icon="['fal', 'search']"/>
                  </button>
                </template>
              </dx-autocomplete>
            </div>
          </div>
          <div class="empty mt-2" v-if="form.expenses.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Nenhum procedimento adicionado</p>
            <p class="empty-subtitle">
              Selecione um convênio e informe parte do nome ou código da procedimento
            </p>
          </div>
          <table class="table table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm">#</th>
              <th>Itens</th>
              <th class="text-center" style="width: 100px">Quantidade</th>
              <th class="text-right" style="width: 110px">Valor Unitário</th>
              <th class="text-right" style="width: 100px">% Desconto</th>
              <th class="text-right" style="width: 150px">Valor Total</th>
              <th style="width: 50px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(expense, i) in form.expenses" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>
                {{ expense.code | tuss }} - {{ expense.name }}<br>
                <small v-if="expense.professional">{{ expense.professional.name }}</small>
              </td>
              <td class="text-center">
                <dx-input-number
                  type="text" class="form-input text-center"
                  v-model="expense.quantity"
                  @change="changeValue(expense)"
                  maxlength="4"
                  :class="{'is-error': $v.form.expenses.$each[i].quantity.$error}"
                />
              </td>
              <td class="text-right">
                <dx-input-number
                  type="text" class="form-input text-right"
                  v-model="expense.value"
                  :precision="2"
                  @change="changeValue(expense)"
                  :class="{'is-error': $v.form.expenses.$each[i].value.$error}"
                />
              </td>
              <td class="text-right">
                <dx-input-number
                  type="text" class="form-input text-center"
                  v-model="expense.discount"
                  maxlength="3"
                  @change="changeValue(expense)"
                />
              </td>
              <td class="text-right">
                <dx-input-number
                  type="text" class="form-input text-right"
                  v-model="expense.final"
                  readonly="true"
                  :precision="2"
                />
              </td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                        data-tooltip="Excluir" @click="removeExpense(i)">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="columns mt-2">
            <div class="column centered">
              <label class="form-switch">
                <input type="checkbox" v-model="payment.show"/>
                <i class="form-icon"/>Informar forma de pagamento?
              </label>
            </div>
            <div class="column centered text-right">
              Valor total: <strong class="text-primary">{{ total | currency }}</strong>
            </div>
          </div>
          <div class="payment-form" v-if="payment.show">
            <h6>Forma de pagamento</h6>
            <div class="columns">
              <div class="column form-group"
                   :class="{'has-error': $v.payment.methodId.$error}">
                <label class="form-label">Método</label>
                <select id="method"
                        class="form-select"
                        v-model="payment.methodId"
                        @blur="$v.payment.methodId.$touch()">
                  <option value="">[Selecione]</option>
                  <option v-for="item in methods" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="column col-auto form-group">
                <label class="form-label">Parcela(s)</label>
                <select id="quantity"
                        class="form-select"
                        v-model="payment.installments">
                  <option v-for="item in installments"
                          :value="item" :key="item">
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="column col-2 col-sm-12 form-group"
                   :class="{'has-error': $v.payment.value.$error}">
                <label class="form-label">Valor</label>
                <dx-input-number
                  id="value"
                  class="form-input text-right"
                  v-model="payment.value"
                  @focus="payment.value = balance"
                  @blur="$v.payment.value.$touch()"
                  :precision="2" maxlength="10" />
              </div>
              <div class="column col-2 col-sm-12 form-group">
                <label class="form-label">Saldo</label>
                <dx-input-number
                  class="form-input text-right"
                  :precision="2"
                  :value="balance"
                  readonly="true"
                />
              </div>
              <div class="column col-auto col-sm-12 form-group mb-2"
                   style="align-self: flex-end">
                <button class="btn btn-primary" @click="addInstallment">Adicionar</button>
              </div>
            </div>
            <table class="table" v-if="form.installments.length > 0">
              <thead>
              <tr>
                <th>#</th>
                <th>Método</th>
                <th class="text-right">Valor</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in form.installments" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.method.name }}</td>
                <td class="text-right">{{ item.value | currency }}</td>
                <td class="text-right">
                  <button
                    class="btn btn-sm btn-action btn-error"
                    @click="removeInstallment(i)">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                :class="{loading: saving}"
                :disabled="saving"
                v-if="!authorized"
                @click="save">
          Salvar
        </button>
        <button class="btn btn-gray mr-1"
                @click="print" :disabled="printing || isNew"
                :class="{loading: printing}">
          Imprimir
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>

    <dx-modal title="Lista de despesas"
              v-model="expenseModal.show" size="lg"
              v-if="expenseModal.show"
              id="modal-budget-expenses">
      <div class="empty mt-2" v-if="expenseModal.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Despesas</p>
        <p class="empty-subtitle">
          Nenhuma despesa encontrado. Verifique as configurações.
        </p>
      </div>
      <table class="table table-hover expense-modal-table" v-else>
        <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th>Profissional</th>
          <th class="text-right">Valor paciente</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in expenseModal.items" :key="i"
            @click="selectExpense(item)">
          <td>
            <span v-if="item.code">{{ item.code | tuss }}</span>
            <span v-else>-</span>
          </td>
          <td>{{ item.expense.name }}</td>
          <td>
            <strong class="text-info" v-if="item.professional">
              {{ item.professional.name }}
            </strong>
            <span v-else>Todos</span>
          </td>
          <td class="text-right">
            {{ item.definition ? item.definition.particularValue : 0 | currency }}
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { required, email, minValue } from 'vuelidate/src/validators';
import * as status from 'src/data/budget-statuses';
import moment from 'moment';
import { mergeFrom } from '@/helpers/object';
import { PRINT_BUDGET } from '@/data/actions/modules/financial';
import formMixin from '../../../mixins/form';
import {
  date,
  phone,
  minDate,
  maxDate,
} from '../../../data/validators';

export default {
  mixins: [formMixin],
  data() {
    return {
      isNew: false,
      status,
      loading: false,
      saving: false,
      printing: false,
      authorized: false,
      insurances: [],
      expenses: [],
      methods: [],
      installments: [],
      patient: null,
      expense: null,
      expenseModal: {
        show: false,
        loading: false,
        items: [],
      },
      payment: {
        show: false,
        installments: 1,
        methodId: '',
        value: '',
      },
      form: this.blankForm(),
    };
  },
  validations() {
    return {
      form: {
        status: { required },
        date: { required, date, minDate: minDate() },
        patient: {
          name: { required },
          phone: { phone },
          birthDate: { date, required, maxDate: maxDate() },
          gender: { required },
          cellphone: { phone },
          email: { email },
        },
        insurance: {
          plan: {
            id: { required },
          },
        },
        expenses: {
          required,
          $each: {
            quantity: { required, minValue: minValue(1) },
            value: { required, minValue: minValue(0.01) },
          },
        },
      },
      payment: {
        methodId: { required },
        value: { required, minValue: minValue(0.01) },
      },
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.loadInsurances();
    this.loadMethods();
    this.loadInstallments();
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  computed: {
    total() {
      return this.form.expenses
        .reduce((a, b) => a + b.final, 0);
    },
    balance() {
      return this.total - this.form.installments.reduce((a, b) => a + b.value, 0);
    },
    canAccessPrintBudget() {
      if (PRINT_BUDGET) {
        return this.$can(PRINT_BUDGET);
      }
      return true;
    },
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`/budgets/${this.form.id}`)
        .then(({ data }) => {
          this.authorized = data.status === 'authorized';
          this.form = mergeFrom(this.blankForm(), data);
          this.form.expenses.forEach((expense) => {
            if (!expense.value) {
              expense.discount = 0;
              expense.value = expense.final / expense.quantity;
            }
            return expense;
          });
          this.patient = data.patient;
          this.payment.show = this.form.installments.length > 0;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadExpense(search) {
      const params = {
        search,
        limit: 30,
        offset: 0,
      };
      if (this.form.insurance.plan.id) {
        params.insurancePlanId = this.form.insurance.plan.id;
      }
      return this.$http
        .get('/expense-pricing', { params })
        .then(({ data }) => data.items);
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
      };
      this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.methods = data.items;
        })
        .catch(() => {});
    },
    loadInstallments() {
      for (let i = 1; i <= 10; i += 1) {
        this.installments = i;
      }
    },
    setName(item) {
      const professional = item.professional && item.professional.name
        ? ` (${item.professional.name})` : '';

      return `${item.expense.name}${professional}`;
    },
    findExpense() {
      const params = {
        insurancePlanId: this.form.insurance.plan.id,
        limit: 30,
        offset: 0,
      };

      return this.$http.get('/expense-pricing', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: '',
              code: '',
              expense: {
                id: '',
                type: '',
                name: 'Procedimento não encontrado neste convênio',
              },
              insurance: {
                id: '',
                type: '',
                name: '',
                customName: '',
                plan: {
                  id: '',
                  name: '',
                },
              },
              professional: null,
              quantity: 0,
              factor: 0,
              definition: {
                insuranceValue: '',
                particularValue: '',
              },
            }];
          }
          return data.items;
        });
    },
    onSelectExpense(item) {
      const found = this.form.expenses
        .find((expense) => {
          if (item.professional && item.professional.id) {
            return expense.id === item.expense.id
              && expense.professional
              && expense.professional.id === item.professional.id;
          }
          return expense.id === item.expense.id;
        });

      if (found) {
        found.quantity += 1;
        found.final = found.value * found.quantity;
      } else {
        this.form.expenses.push({
          id: item.expense.id,
          code: item.code,
          name: item.expense.name,
          professional: item.professional,
          quantity: 1,
          value: item.definition.particularValue,
          final: item.definition.particularValue,
        });
      }

      this.expense = null;
      this.expenses = [];
    },
    async openExpenseModal() {
      this.expenseModal.loading = true;
      this.expenseModal.items = [];
      await this.findExpense()
        .then((items) => {
          if (items) {
            this.expenseModal.items = items;
          }
        }).catch(() => {})
        .finally(() => {
          this.expenseModal.loading = false;
        });
      this.expenseModal.show = true;
    },
    selectExpense(item) {
      this.onSelectExpense(item);
      this.expenseModal.show = false;
    },
    removeExpense(i) {
      this.form.expenses.splice(i, 1);
    },
    changeValue(item) {
      if (item.discount > 100) {
        item.discount = 0;
      }
      const quantity = item.quantity || 1;
      const value = item.value - (item.value * (item.discount / 100));
      item.final = value * quantity;
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      if (this.form.expenses.length === 0) {
        this.$toast.show('Informe ao menos um procedimento', { type: 'error' });
        return;
      }

      this.saving = true;

      if (!this.payment.show) {
        this.form.installments = [];
      }

      const data = this.clone(this.form);
      data.insurancePlanId = data.insurance.plan.id;

      const request = this.isNew
        ? this.$http.post('/budgets', data)
        : this.$http.put(`/budgets/${this.form.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`/financial/budgets/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
            this.form.patient.id = result.patient.id;
            this.patient = this.patient || {};
            this.patient.id = result.patient.id;
            this.patient.name = result.patient.name;
          }
          this.authorized = result.status === 'authorized';
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .finally(() => {
          this.saving = false;
        });
    },
    addInstallment() {
      this.$v.payment.$touch();
      if (this.$v.payment.$error) {
        return;
      }
      if ((this.payment.value * this.payment.installments) > this.balance) {
        this.$toast.error('O valor não pode ser superior ao saldo!');
        return;
      }

      const method = this.methods.find(({ id }) => id === this.payment.methodId);
      for (let i = 1; i <= this.payment.installments; i += 1) {
        this.form.installments.push({
          method: {
            id: method.id,
            name: method.name,
          },
          value: this.payment.value,
        });
      }

      this.payment.installments = 1;
      this.payment.methodId = '';
      this.payment.value = '';
      this.$v.payment.$reset();
    },
    removeInstallment(i) {
      this.$v.payment.$reset();
      this.form.installments.splice(i, 1);
    },
    print() {
      if (!this.canAccessPrintBudget) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return false;
      }
      if (!this.form.id) {
        return null;
      }

      this.printing = true;

      return this.$file
        .print(`/budgets/${this.form.id}/print`)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    findPatient(search) {
      return this.$http.get(`/patients?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setPatient(data) {
      this.patient = data;
      this.form.patient = data;
    },
    onPatientBlur(text) {
      this.form.patient.name = text;
      this.$v.form.patient.name.$touch();
    },
    unsetPatient() {
      this.patient = null;
      this.form.patient = this.blankPatientForm();
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.apiGetInsurances(params)
        .then((data) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    apiGetInsurances(params) {
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => data);
    },
    apiGetMethods(params) {
      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => data)
        .catch(() => {});
    },
    setValidityDate() {
      if (!this.form.date) {
        this.form.date = moment().add('10', 'days').format('YYYY-MM-DD');
      }
    },
    blankPatientForm() {
      return {
        id: '',
        name: '',
        birthDate: '',
        gender: '',
        phone: '',
        cellphone: '',
        email: '',
      };
    },
    blankForm() {
      return {
        id: '',
        code: '',
        status: '',
        date: moment().add(30, 'days').format('YYYY-MM-DD'),
        total: '',
        patient: this.blankPatientForm(),
        insurance: {
          id: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
        },
        collaborator: {
          id: '',
          name: '',
        },
        notes: '',
        expenses: [],
        installments: [],
      };
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .budget-page {
    .payment-form {
      background-color: $gray-color-ultra-light;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      margin-top: $layout-spacing-lg;
      padding: $layout-spacing-lg;
      .table td {
        background-color: $light-color;
      }
    }
  }
  #modal-budget-expenses {
    .table th, .table td {
      font-size: 0.55rem;
    }
  }
</style>
